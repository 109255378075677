import { useEffect, useState } from "react";
import api from "../../../services/api";
import Button from "./Button";
import ContractFooter from "./ContractFooter";
import TextEditor from "./TextEditor";
import AttachedFiles from "./AttachedFiles";

const ModalContract = ({
  setModalActive,
  closeModal,
  onClick,
  id,
  contract,
  client,
  urls,
  btnActive,
}) => {
  const [partner, setPartner] = useState({});

  const getProfile = async () => {
    try {
      const response = await api.get("/profile");

      setPartner(response.data);
      console.log(response.data.cnpj);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfile();
    console.log(client);
  }, []);

  useEffect(() => {
    console.log(partner);
  }, [partner]);

  return (
    <div
      style={{
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        height: "679px",
        width: "754px",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "22px",
          borderBottom: "1px solid #D7D7D7",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "21px",
          }}
        >
          <div onClick={closeModal} style={{ cursor: "pointer" }}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
                fill="black"
              />
            </svg>
          </div>
          <p className="textTitleCloseModal">
            {btnActive
              ? "Assine o contrato digitalmente"
              : "Visualize o contrato assinado"}
          </p>
        </div>
        {btnActive && (
          <Button
            onClick={() => onClick(id)}
            height="33px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Assinar"
            selectBtn={"Assinar"}
            border="none"
            width="134px"
          />
        )}
      </div>

      <div
        style={{
          height: "84%",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextEditor
          value={contract}
          name={client.name}
          address={client.address}
          cpf={client.cpf}
          rg={client.rg}
          company={client.company}
          cnpj={client.cnpj}
          phone={client.phone}
          partner={partner}
          representative={client?.representative}
          nationality={client?.nationality}
          marital={client?.marital_status}
          profession={client?.profession}
        />

        <AttachedFiles urls={urls} />

        <ContractFooter
          contractedName={client.representative}
          cnpj={client.cnpj}
          contractingCnpj={partner.cnpj}
          contractingName={partner.representative}
        />
      </div>
    </div>
  );
};

export default ModalContract;
