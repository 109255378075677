import { useContext } from "react"
import Button from "./Button"
import { DashboardPartnerContext } from "../../../context/Dashboard Partner/DashboardContext"


const Modaldelete = ({ close, id}) => {
    const { onSubmitDestroyOpportunities } = useContext(DashboardPartnerContext)
    
    const container = {
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        minHeight: "375px",
        width: "500px",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.15)"
    }

    return (
        <div>
            <div className="modalEdit" style={container}>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "22px",
                    borderBottom: "1px solid #D7D7D7"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "21px"
                    }}>
                        <div onClick={close} style={{ cursor: "pointer" }}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z" fill="black" />
                            </svg>
                        </div>
                        <p className="textTitleCloseModal">Excluir oportunidade</p>
                    </div>

                    <Button height="33px" background="#FFE1E1" color={"#EA0000"}
                        borderRadius="60px" marginTop="0" name={"Excluir"} selectBtn={"Excluir"}
                        border="none" width="134px" onClick={()=> onSubmitDestroyOpportunities(id)} />

                </div>

                <div style={{
                    padding: "20px 20px 0"
                }}>
                    <p className="textConfirmationRemove">Tem certeza que deseja excluir a oportunidade?</p>
                </div>
            </div>
        </div>
    )
}

export default Modaldelete