import React, { useState, useEffect, useContext } from 'react'
import ThowColumLayout from '../../../layouts/ThowColumLayout'
import Sidebar from '../components/Sidebar'
import HeaderDashboard from '../components/HeaderDashboard'
import FilterUsers from '../components/FilterUsers'
import CardSignature from '../components/CardSignature'
import api from '../../../services/api'
import CardOpportunities from '../components/CardOpportunities'
import { DashboardPartnerContext } from '../../../context/Dashboard Partner/DashboardContext'
import CreateOpportunities from '../components/CreateOpportunities'
import ModalContainer from '../components/ModalContainer'
import Modaldelete from '../components/Modaldelete'


const Opportunities = () => {
    const { modal,
        setModal,
        modalDelete,
        setModalDelete,
        opportunities,
        onSubmitAddOpportunities,
        onSubmitUpdateOpportunities,
        getFilterOpportunities,
        opportunitiesFilter
    } = useContext(DashboardPartnerContext)

    const [modalEdit, setModalEdit] = useState(false)

    const [idEdit, setIdEdit] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const letterLimit = (text, limit, threePoints) => {
        let description = []

        if (threePoints) {
            text.split("").map(res => {
                if (description.length <= Number(limit)) {
                    description.push(res)
                } else if (description.length == Number(limit) + 1) {
                    description = `${description.join("")}...`
                }
            })
        } else {
            text.split("").map(res => {
                if (description.length <= Number(limit)) {
                    description.push(res)
                } else if (description.length == Number(limit) + 2) {
                    description = `${description.join("")}...`
                }
            })
        }

        return description
    }

    useEffect(() => {
        if (modal == false) {
            setModalEdit(false)
        }
    }, [modal])

    const stylesWap = {
        display: "flex",
        gap: "20px",
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "space-between",
    }

    const stylesNotWap = {
        display: "flex",
        gap: "20px",
        justifyContent: "space-between",
    }

    return (

        <ThowColumLayout
            page={"opportunities"}
            colum1={
                <Sidebar access="partner" page="opportunities" />
            }

            colum2={
                <>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "45px"
                    }}>

                        <HeaderDashboard name="Oportunidades" />

                        <FilterUsers onChange={getFilterOpportunities} onClick={() => setModal(true)} page="opportunities" />
                    </div>

                    <div className="containerBox" style={{
                        display: "flex",
                        marginBottom: '50px',
                    }}>
                        <div style={opportunities.length < 3 ? stylesNotWap : stylesWap}>
                            {
                                opportunitiesFilter.length > 0 && opportunitiesFilter.map(item => {

                                    const description = letterLimit(item.description, 130, true)

                                    return (
                                        <CardOpportunities
                                            modalEdit={modalEdit}
                                            setModalEdit={setModalEdit}
                                            name={item.name}
                                            onClick={() => {
                                                setName(item.name)
                                                setDescription(item.description)
                                                setIdEdit(item.id)
                                            }}
                                            description={description} />
                                    )
                                })
                            }

                        </div>

                        <ModalContainer isActive={modal} closeModal={() => {
                            setModal(false)
                            setModalEdit(false)
                        }} >
                            {
                                modalEdit == true ?
                                    <CreateOpportunities
                                        id={idEdit}
                                        valueName={name}
                                        valueDescription={description}
                                        onUpdated={(data) => {
                                            onSubmitUpdateOpportunities(data, idEdit)
                                        }}
                                        type="edit" close={() => {
                                            setModal(false)
                                            setModalEdit(false)
                                        }} />
                                    :
                                    <CreateOpportunities
                                        onCreated={onSubmitAddOpportunities}
                                        close={() => {
                                            setModal(false)
                                            setModalEdit(false)
                                        }} />
                            }
                        </ModalContainer>

                        <ModalContainer isActive={modalDelete} closeModal={() => {
                            setModalDelete(false)
                        }} >
                            <Modaldelete id={idEdit} close={() => {
                                setModalDelete(false)
                            }} />
                        </ModalContainer>

                    </div>
                </>
            }


        >

        </ThowColumLayout>

    )

}

export default Opportunities