import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

export const AuthContext = createContext({});

export const AuthValidation = ({ children }) => {
  const [checkTerms, setCheckTerms] = useState(false)
  const [email, setEmail] = useState("email@mail.com");
  const navigate = useNavigate();

  /* CLIENT */
  const onSubmitFormLoginClient = async (data) => {
    try {
      const response = await api.post("/session/client", data);
      const { token } = response.data;

      try {
        const profileToken = await api.get(`/profile/user/token/${token}`);
        const { type } = profileToken.data

        if (type == 'Funcionario') {
          localStorage.setItem("@QuboEmployee:", token);
          navigate("/dashboard/trail/employee", { replace: true });
        }

        if (type == 'Administrativo') {
          localStorage.setItem("@QuboClient:", token);
          navigate("/dashboard", { replace: true });
        }

        if (type == 'Cliente') {
          localStorage.setItem("@QuboClient:", token);
          navigate("/dashboard", { replace: true });
        }

      } catch (err) {
        const message = err.response.data.error;
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }


      //localStorage.setItem("@QuboClient:", token);
      //navigate("/dashboard", { replace: true });

    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const onSubmitFormRegisterClient = async (data) => {
    try {
      if (!checkTerms) {
        return toast.error("Aceite os termos para continuar", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      await api.post("/client", data);

      const message = "Conta criada com sucesso."

      toast.success(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate("/");
      }, 1000);


    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* ADMIN */
  const onSubmitFormLoginAdm = async (data) => {
    try {
      const response = await api.post("/session/admin", data);
      const { token } = response.data;

      localStorage.setItem("@QuboAdmn:", token);
      navigate("/dashboard/admin", { replace: true });

    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* PARTNER */
  const onSubmitFormLoginPartner = async (data) => {
    try {
      const response = await api.post("/session/partner", data);
      const { token } = response.data;

      console.log(token)

      localStorage.setItem("@QuboPartner:", token);
      navigate("/dashboard", { replace: true });

    } catch (err) {
      const message = err.response.data.error;
      console.log(err)
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* EXEMPLOS */

  const onSubmitFormRecovery = async (data) => {
    try {
      await api.post("/send", data);
      setEmail(data.email);

      navigate("/sending");
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onSubmitFormRecoverySend = async (data) => {
    try {
      await api.post("/send", { email: email });

      toast.success("Email enviado", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onSubmitFormResetPassword = async (data) => {
    const { new_password, confirm_password, idParam } = data;
    if (new_password !== confirm_password) {
      toast.error("Senhas diferentes!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      try {
        await api.patch(`/user/${idParam}`, { password: new_password });
        toast.success("Senhas atualizada", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } catch (err) {
        const message = err.response.data.message;
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          onSubmitFormRecovery,
          onSubmitFormRecoverySend,
          onSubmitFormResetPassword,
          checkTerms,
          setCheckTerms,
          email,
          onSubmitFormLoginClient,
          onSubmitFormLoginPartner,
          onSubmitFormRegisterClient,
          onSubmitFormLoginAdm
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
