import React, { createContext, useEffect, useState } from 'react'
import api from '../../services/api'
import { toast } from 'react-toastify'
export const UserContext = createContext({})

const UserProvider = ({ children }) => {

    const [users, setUsers] = useState([])
    const [data, setData] = useState([])

    const getByName = async (value) => {

        if (value == "") {

            const response = await api.get("user")
            setData(response.data)
            return false
        }

        const response = await api.get(`/user/by/name/${value}`)

        if (response.status == 404) {
            setData([])
        }

        setData(response.data)
    }

    useEffect(() => {
        const token = localStorage.getItem("@QuboClient:")

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        if (token) {

            const getUser = async () => {
                try {
                    const listUser = await api.get("/user", config)

                    setUsers(listUser.data)

                } catch (error) {

                }
            }

            getUser()
        }
    }, [])

    const onSubmitAddUser = async (data, setState) => {
        const token = localStorage.getItem("@QuboClient:")

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        try {
            if (token) {

                const createUser = await api.post("/user", data, config)

                setUsers([...users, createUser.data])

                toast.success("Usuario adicionado com sucesso", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    setState(false)
                }, 800);
            }
        } catch (err) {
            const message = err.response.data.error;
            toast.error(`${message}`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const onSubmitUpdateUser = async (id, data, setState) => {
        const token = localStorage.getItem("@QuboClient:")

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        try {
            await api.patch(`/user/${id}`, data, config)

            const listUser = await api.get("/user", config)

            setUsers(listUser.data)

            toast.success("Atualização do usuario realizada com sucesso", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                setState(false)
            }, 800);
        } catch (err) {
            const message = err.response.data.error;
            toast.error(`${message}`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const onSubmitDeleteUser = async (id) => {
        const token = localStorage.getItem("@QuboClient:")

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        try {
            await api.delete(`user/${id}`, config)

            const listUser = await api.get("/user", config)

            setUsers(listUser.data)

            toast.success("Usuario deletado com sucesso", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            const message = err.response.data.error;
            toast.error(`${message}`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }



    return (
        <UserContext.Provider value={{
            users,
            onSubmitAddUser,
            onSubmitUpdateUser,
            onSubmitDeleteUser,
            getByName
        }}>
            {children}
        </UserContext.Provider>
    )

}

export default UserProvider