import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:8080",
  baseURL: "https://api.guidz.com.br",
  // baseURL: "https://api-guidz-dot-absolute-router-392014.de.r.appspot.com",
  timeout: 10000,
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("@QuboPartner:");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
