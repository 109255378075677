import React, { useState, useEffect, useContext } from "react";

import ThowColumLayoutLogin from "../../../layouts/ThowColumLayoutLogin";
import loginIconPartner from "../../../images/svg/loginIconPartner.svg";
import companyIcon from "../../../images/svg/companyIcon.svg";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Input from "../components/Input";
import { AuthContext } from "../../../context/Auth/AuthContext";

const LandingPartner = () => {
  const { onSubmitFormLoginPartner } = useContext(AuthContext);

  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const container = {
    width: "418px",
    margin: "0 auto",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "1.3125rem",
  };

  const styleTitle = {
    width: "500px",
    marginBottom: "15px",
  };

  /* MESSAGE ERROS */

  const [mailError, setMailError] = useState();
  const [paswordError, setPasswordError] = useState();

  /* BTN STATE */

  const [btnState, setBtnState] = useState();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const getLogin = async () => {
    if (password == undefined) {
      setPasswordError("senha deve conter no mínimo 8 caracteres");
    }

    const validMail = validateEmail(mail);

    if (validMail == false) {
      setMailError("email inválido");
      setBtnState(true);
    } else {
      setMailError();
      setBtnState(false);
    }

    if (String(password).length <= 7) {
      setPasswordError("senha deve conter no mínimo 8 caracteres");
      setBtnState(true);
    } else {
      setBtnState(false);
      setPasswordError();
    }

    if (mail != undefined && password != undefined && validMail != false) {
      const data = {
        email: mail,
        password: password,
      };
      onSubmitFormLoginPartner(data);
    }
  };

  const containerBanner = {
    position: "fixed",
    width: "45%",
    backgroundColor: "#F8F9FF",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    paddingTop: "2vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const image = {
    width: "480px",
    height: "480px",
    marginTop: "-40px",
    display: "flex",
    alignItems: "center",
  };

  const containerColum2 = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "100vh",
  };

  useEffect(() => {
    if (mail != undefined) {
      const validMail = validateEmail(mail);

      if (validMail == false) {
        setMailError("email inválido");
        setBtnState(true);
      } else {
        setMailError();
        setBtnState(false);
      }
    }

    if (password != undefined) {
      if (String(password).length <= 7) {
        setPasswordError("senha deve conter no mínimo 8 caracteres");
        setBtnState(true);
      } else {
        setBtnState(false);
        setPasswordError();
      }
    }
  }, [mail, password]);

  return (
    <ThowColumLayoutLogin
      page={"LandingPartner"}
      // colum1={
      //     <div style={containerBanner}>
      //         <h1 className='textTitle-Login' style={{ alignItems: "center", width: "78%" }}>Evolua sua empresa com a Guidz</h1>
      //         <p className='textSubtitle-Login' style={{ alignItems: "center", width: "78%" }}>Tenha acesso a uma trinha de conhecimento, faça networking com outros empresarios e muito mais...</p>
      //         <img src={loginIconPartner} style={image} />
      //     </div>
      // }
      colum1={
        <div style={containerBanner}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              margin: "0 0 0 100px",
            }}
          >
            <img
              src={companyIcon}
              style={{ height: "60px", marginRight: "20px" }}
            />
            <h1 className="textTitle-login" style={styleTitle}>
              Aqui começa a jornada mais importante da sua Empresa!
            </h1>
          </div>
          <img
            src={loginIconPartner}
            style={image}
            alt="imagem inicial de login"
          />
        </div>
      }
      colum2={
        <div style={containerColum2}>
          <div style={container}>
            <img src={companyIcon} />

            <h2 className="textPrimaryLanding">Dados de acesso</h2>

            <Input
              name="E-mail"
              error={mailError}
              onChange={(e) => setMail(e.target.value)}
              placeholder="Insira seu e-mail"
              type={"text"}
            />
            <Input
              name="Senha"
              error={paswordError}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Insira sua senha"
              type={"password"}
            />

            <div style={boxFooterButton}>
              <Button
                width="100%"
                height="48px"
                background="var(--color-primary)"
                borderRadius="60px"
                marginTop="0"
                name="Entrar"
                border="none"
                disabled={btnState}
                onClick={() => getLogin()}
              />
            </div>
          </div>
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default LandingPartner;
