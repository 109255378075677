import { Routes, Route, Navigate } from "react-router-dom";
import LandingPartner from "../pages/LandingPartner/Initial";
import DashboardPartner from "../pages/DashboardPartner/Initial";
import Opportunities from "../pages/DashboardPartner/Opportunities";
import Proposals from "../pages/DashboardPartner/Proposals";


export const RoutesUrl = () => {

  return (
    <Routes>
      <Route path="*" element={<LandingPartner />} />
      <Route path="/" element={<LandingPartner />} />
      <Route path="/dashboard" element={<DashboardPartner />} />
      <Route path="/dashboard/proposals" element={<Proposals />} />
      <Route path="/dashboard/opportunities" element={<Opportunities />} />
    </Routes>
  );
};
