import React, { useState, useEffect } from "react";
import { convertFromRaw, EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({
  value,
  name,
  company,
  cnpj,
  phone,
  address,
  cpf,
  rg,
  contractingCnpj,
  signContractClient,
  setContractFormact,
  setLoading,
  partner,
  representative,
  nationality,
  marital,
  profession,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [nameEditor, setNameEditor] = useState("");
  const [cnpjEditor, setCnpjEditor] = useState("");
  const [contractingCnpjEditor, setContractingCnpjEditor] = useState("");

  const [companyEditor, setCompanyEditor] = useState("");
  const [phoneEditor, setPhoneEditor] = useState("");
  const [addressEditor, setAddressEditor] = useState("");

  const [addressEditorPartner, setAddressEditorPartner] = useState("");
  const [cpfEditor, setCpfEditor] = useState("");
  const [representativeEditorPartner, setRepresentativeEditorPartner] =
    useState("");

  const [representativeEditor, setRepresentativeEditor] = useState("");
  const [nationalityEditor, setNationalityEditor] = useState("");
  const [maritalEditor, setMaritalEditor] = useState("");
  const [professionEditor, setProfessionEditor] = useState("");

  const [representativePartnerEditor, setRepresentativePartnerEditor] =
    useState("");
  const [nationalityPartnerEditor, setNationalityPartnerEditor] = useState("");
  const [maritalPartnerEditor, setMaritalPartnerEditor] = useState("");
  const [professionPartnerEditor, setProfessionPartnerEditor] = useState("");
  const [cpfPartnerEditor, setCpfPartnerEditor] = useState("");
  const [rgPartnerEditor, setRgPartnerEditor] = useState("");
  const [namePartnerEditor, setNamePartnerEditor] = useState("");
  const [rgEditor, setRgEditor] = useState("");

  useEffect(() => {
    setNameEditor(name);
    setCompanyEditor(company);
    setCnpjEditor(cnpj);
    setContractingCnpjEditor(partner.cnpj);
    setPhoneEditor(phone);
    setAddressEditor(address);
    setAddressEditorPartner(partner.addresses);
    setRepresentativePartnerEditor(partner.representative);
    setCpfEditor(cpf);
    setRgEditor(rg);
    setRepresentativeEditor(representativeEditor);
    setNationalityEditor(nationality);
    setMaritalEditor(marital);
    setProfessionEditor(profession);

    setNationalityPartnerEditor(partner.nationality);
    setMaritalPartnerEditor(partner.marital_status);
    setProfessionPartnerEditor(partner.profession);
    setCpfPartnerEditor(partner.cnpj);
    setRgPartnerEditor(partner.rg);
    setNamePartnerEditor(partner.name);
  }, [
    name,
    company,
    cnpj,
    phone,
    partner,
    address,
    cpf,
    rg,
    representativeEditor,
    nationality,
    marital,
    profession,
  ]);

  useEffect(() => {
    if (value) {
      const parsedContent = JSON.parse(value);
      //console.log(parsedContent)
      const teste = parsedContent.blocks.map((item) => {
        item.text = item.text.replaceAll("{nome}", companyEditor);
        item.text = item.text.replaceAll(
          "{cnpj-contratada}",
          contractingCnpjEditor
        );
        item.text = item.text.replaceAll("{cnpj-contratante}", cnpjEditor);
        item.text = item.text.replaceAll(
          "{address-contratada}",
          addressEditorPartner
        );
        item.text = item.text.replaceAll("{cpf-contratante}", cpfEditor);

        item.text = item.text.replaceAll(
          "{name-representative-contratante}",
          representativeEditor
        );
        item.text = item.text.replaceAll(
          "{nationality-representative-contratante}",
          nationalityEditor
        );
        item.text = item.text.replaceAll(
          "{marital-representative-contratante}",
          maritalEditor
        );
        item.text = item.text.replaceAll(
          "{profession-representative-contratante}",
          professionEditor
        );
        item.text = item.text.replaceAll(
          "{rg-representative-contratante}",
          rgEditor
        );
        item.text = item.text.replaceAll(
          "{address-representative-contratante}",
          addressEditor
        );

        item.text = item.text.replaceAll(
          "{name-representative-contratada}",
          representativePartnerEditor
        );
        item.text = item.text.replace(
          representativePartnerEditor,
          namePartnerEditor
        );
        item.text = item.text.replaceAll(
          "{nationality-representative-contratada}",
          nationalityPartnerEditor
        );

        item.text = item.text.replaceAll(
          "{marital-representative-contratada}",
          maritalPartnerEditor
        );
        item.text = item.text.replaceAll(
          "{profession-representative-contratada}",
          professionPartnerEditor
        );
        item.text = item.text.replaceAll("{cpf-contratada}", cpfPartnerEditor);
        item.text = item.text.replaceAll(
          "{rg-representative-contratada}",
          rgPartnerEditor
        );

        item.text = item.text.replaceAll("{cpf-contratante}", cpfEditor);
        item.text = item.text.replaceAll("{rg-contratante}", rgEditor);
        item.text = item.text.replaceAll("{empresa}", companyEditor);
        item.text = item.text.replaceAll("{telefone}", phoneEditor);
        item.text = item.text.replaceAll(
          "{address-contratante}",
          addressEditor
        );
      });

      const contentState = convertFromRaw(parsedContent);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [
    value,
    nameEditor,
    cnpjEditor,
    contractingCnpjEditor,
    companyEditor,
    phoneEditor,
    addressEditorPartner,
  ]);

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <Editor
        editorState={editorState}
        readOnly
        toolbarHidden
        wrapperClassName="text-editor-wrapper"
        editorClassName="text-editor-content"
      />
    </div>
  );
};

export default TextEditor;
