// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modalEdit {
  animation-name: modalEditAnimation;
  animation-duration: 0.5s;
}

@keyframes modalEditAnimation {
  0% {
    transform: translateY(100vh);
  }
}

.cardQuestionsAnimation{
  animation-name: cardQuestions;
  animation-duration: 2s;
}

@keyframes cardQuestions {
  0% {
    height: 106px;
  }
  100% {
    height: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/animations.css"],"names":[],"mappings":";AACA;EACE,kCAAkC;EAClC,wBAAwB;AAC1B;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,6BAA6B;EAC7B,sBAAsB;AACxB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,YAAY;EACd;AACF","sourcesContent":["\n.modalEdit {\n  animation-name: modalEditAnimation;\n  animation-duration: 0.5s;\n}\n\n@keyframes modalEditAnimation {\n  0% {\n    transform: translateY(100vh);\n  }\n}\n\n.cardQuestionsAnimation{\n  animation-name: cardQuestions;\n  animation-duration: 2s;\n}\n\n@keyframes cardQuestions {\n  0% {\n    height: 106px;\n  }\n  100% {\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
