import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";

export const DashboardPartnerContext = createContext({});

export const DashboardPartnerValidation = ({ children }) => {
  const [modal, setModal] = useState(false)
  const [modalActiveContract, setModalActiveContract] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [partnerId, setPartnerId] = useState('')

  const [opportunities, setOpportunities] = useState([])
  const [opportunitiesFilter, setOpportunitiesFilter] = useState([])

  /* OPPORTUNITY PARTNER */
  /* ----------------------------------------- */
  const getProfile = async () => {
    const token = localStorage.getItem('@QuboPartner:')

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const profile = await api.get('/profile', config)
      setPartnerId(profile.data.id)

    } catch (error) {
      console.log(error)

    }

  }

  /* CREATE OPPORTUNITES */
  const onSubmitAddOpportunities = async (data) => {
    try {
      console.log(data)

      await api.post("/opportunity", data);

      const getOpportunities = await api.get(`/opportunity/${partnerId}`);

      setOpportunities(getOpportunities.data)
      setOpportunitiesFilter(getOpportunities.data)
      toast.success("Oportunidade criada com sucesso!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setModal(false)
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* GET OPPORTUNITES */
  const onSubmitGetOpportunities = async (id) => {
    try {
      const getOpportunities = await api.get(`/opportunity/${id}`);

      setOpportunities(getOpportunities.data)
      setOpportunitiesFilter(getOpportunities.data)
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* UPDATE OPPORTUNITES */
  const onSubmitUpdateOpportunities = async (data, id) => {
    try {
      await api.patch(`/opportunity/${id}`, data);

      const getOpportunities = await api.get(`/opportunity/${partnerId}`, data);

      setOpportunities(getOpportunities.data)
      setOpportunitiesFilter(getOpportunities.data)

      toast.success("Oportunidade editada com sucesso!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setModal(false)
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* DELETE MACRO */
  const onSubmitDestroyOpportunities = async (id) => {
    try {
      await api.delete(`/opportunity/${id}`);

      const getOpportunities = await api.get(`/opportunity/${partnerId}`);

      setOpportunities(getOpportunities.data)
      setOpportunitiesFilter(getOpportunities.data)

      toast.success("Oportunidade deletada com sucesso", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setModalDelete(false)
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* GET FILTER OPPORTUNITES */
  const getFilterOpportunities = async (value) => {
    if (String(value).length == 0) {
      return setOpportunitiesFilter(opportunities)
    }

    const filter = opportunities.filter(item => {
      if (item.name.toLowerCase().includes(value.toLowerCase())) {
        return item
      }

    })

    setOpportunitiesFilter(filter)
  }

  /* CUSTOMER  PROPOSALS */
  /*----------------------------------------------- */

  const onSubmitAddCustomerProposal = async (data, id) => {
    try {
      await api.post(`/customer/proposals/${id}`, data)

      return true

    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* GET PROFILE */
  useEffect(() => {
    getProfile()
  }, [])

  /* GET ALL OPPORTUNITIES */
  useEffect(() => {
    if (partnerId) {
      onSubmitGetOpportunities(partnerId)
    }
  }, [partnerId])

  return (
    <>
      <DashboardPartnerContext.Provider
        value={{
          modal,
          setModal,
          onSubmitAddOpportunities,
          onSubmitGetOpportunities,
          onSubmitUpdateOpportunities,
          getProfile,
          modalDelete,
          setModalDelete,
          opportunities,
          onSubmitDestroyOpportunities,
          getFilterOpportunities,
          opportunitiesFilter,
          onSubmitAddCustomerProposal,
          modalActiveContract, 
          setModalActiveContract
        }}
      >
        {children}
      </DashboardPartnerContext.Provider>
    </>
  );
};
