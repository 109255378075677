import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";

export const DashboardContext = createContext({});

export const DashboardValidation = ({ children }) => {
  const location = useLocation();

  const [users, setUsers] = useState([])
  const [usersFilter, setUsersFilter] = useState([])
  const [macro, setMacro] = useState([])
  const [column, setColumn] = useState([])

  const [journey, setJourney] = useState([])
  const [initialQuestions, setInitialQuestions] = useState([])
  const [partners, setPartners] = useState([])

  const [partner, setPartner] = useState({})
  const [userPartner, setUserPartner] = useState({})


  /* GET USERS */
  useEffect(() => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    const getUsers = async () => {
      try {
        const listUsers = await api.get("/client", config);
        setUsers(listUsers.data)
        setUsersFilter(listUsers.data)

      } catch (error) {
        console.log(error)
      }

    }

    location.pathname === "/dashboard/admin" && getUsers()

  }, [location])

  /* GET SETTINGS*/
  useEffect(() => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    if (token) {

      const getMacro = async () => {
        try {
          const listMacro = await api.get("/settings/macro", config);
          setMacro(listMacro.data)
        } catch (error) {
          console.log(error)
        }
      }

      const getColumn = async () => {
        try {
          const listColumn = await api.get("/settings/column", config);
          setColumn(listColumn.data)
        } catch (error) {
          console.log(error)
        }
      }

      location.pathname === "/dashboard/settings/admin" && getMacro()
      location.pathname === "/dashboard/settings/admin" && getColumn()
    }

  }, [location])

  /* GET JOURNEY*/
  useEffect(() => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }



    const getJourney = async () => {
      if (token) {
        try {
          const listJourney = await api.get("/journey", config);
          setJourney(listJourney.data)

          const listMacro = await api.get("/settings/macro", config);
          setMacro(listMacro.data)

          const listColumn = await api.get("/settings/column", config);
          setColumn(listColumn.data)
        } catch (error) {
          console.log(error)
        }
      }
    }


    location.pathname === "/dashboard/journey/admin" && getJourney()

  }, [location])

  /* GET INITIAL QUESTIONS*/
  useEffect(() => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    const getInitialQuestions = async () => {
      if (token) {
        const listInitialQuestions = await api.get("/initial/question", config);
        setInitialQuestions(listInitialQuestions.data)
      }
    }
    location.pathname === "/dashboard/questions/admin" && getInitialQuestions()

  }, [location])

  /* GET PARTNERS*/
  useEffect(() => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    const getPartners = async () => {
      if (token) {
        try {
          const listPartners = await api.get("/partner", config);
          setPartners(listPartners.data)
        } catch (error) {
          console.log(error)
        }
      }
    }

    location.pathname === "/dashboard/partners/admin" && getPartners()

  }, [location])


  /* MACRO */
  /* ADD MACRO */
  const onSubmitAddMacro = async (data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      if (token) {
        await api.post("/settings/macro", data, config);

        const getMacro = await api.get("/settings/macro", config);

        setMacro(getMacro.data)
      }
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* GET BY ID MACRO */
  const onSubmitGetByIdMacro = async (id) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      if (id && token) {
        const getMacro = await api.get(`/settings/macro/${id}`, config);
        return getMacro
      }
      return []
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* UPDATE MACRO */
  const onSubmitUpdateMacro = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      if (token) {
        await api.patch(`/settings/macro/${id}`, data, config);
      }

    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* DELETE MACRO */
  const onSubmitDeleteMacro = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      await api.delete(`/settings/macro/${id}`, config);

      const getMacro = await api.get("/settings/macro", config);

      setMacro(getMacro.data)
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* COLUMN */
  /* ADD COLUMN */
  const onSubmitAddColumn = async (data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      await api.post("/settings/column", data, config);

      const getColumn = await api.get("/settings/column", config);

      setColumn(getColumn.data)
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* GET BY ID COLUMN */
  const onSubmitGetByIdColumn = async (id) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      if (id) {
        const getColumn = await api.get(`/settings/column/${id}`, config);
        return getColumn
      }
      return []
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* UPDATE COLUMN */
  const onSubmitUpdateColumn = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      await api.patch(`/settings/column/${id}`, data, config);

    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* DELETE COLUMN */
  const onSubmitDeleteColumn = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")
    console.log("column")
    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      await api.delete(`/settings/column/${id}`, config);

      const getColumn = await api.get("/settings/column", config);

      setColumn(getColumn.data)
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }


  /* ------------ JOURNEY ------------------ */
  /* ADD JOURNEY */
  const onSubmitAddJourney = async (data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const journeyCreated = await api.post("/journey", data, config);

      return journeyCreated
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* UPDATE JOURNEY */
  const onSubmitUpdateJourney = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const journeyUpdate = await api.patch(`/journey/${id}`, data, config);

      return journeyUpdate
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* GET JOURNEY */
  const onSubmitGetJourney = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const listJourney = await api.get("/journey", config);
      setJourney(listJourney.data)
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* DELETE JOURNEY */
  const onSubmitDeleteJourney = async (id) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      await api.delete(`/journey/${id}`, config);

      toast.success("Parceiro deletado com sucesso!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const listJourney = await api.get("/journey", config);
      setJourney(listJourney.data)
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }


  /* ------------ QUESTION ------------------ */

  /* ADD QUESTION */
  const onSubmitAddQuestion = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const questionCreate = await api.post(`/question/${id}`, data, config);
      const listJourney = await api.get("/journey", config);

      setJourney(listJourney.data)

      return questionCreate
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* UPDATE QUESTION */
  const onSubmitUpdateQuestion = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const questionUpdate = await api.patch(`/question/${id}`, data, config);
      const listJourney = await api.get("/journey", config);

      setJourney(listJourney.data)

      return questionUpdate
    }
    catch (err) {
      console.log(err.response)
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* GET BY ID */
  const onSubmitGetByIdQuestion = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const questionUpdate = await api.get(`/journey/${id}/question/`, data, config);

      return questionUpdate
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* DELETE QUESTION */
  const onSubmitDeleteQuestion = async (id) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const questionDelete = await api.delete(`/question/${id}`, config);
      const listJourney = await api.get("/journey", config);

      setJourney(listJourney.data)

      return questionDelete
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* ------------ INITIAL QUESTION ------------------ */

  /* ADD INITIAL QUESTION */
  const onSubmitAddInitialQuestion = async (data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const initialQuestionCreate = await api.post("/initial/question", data, config);

      const listInitialQuestions = await api.get("/initial/question", config);
      setInitialQuestions(listInitialQuestions.data)


      return initialQuestionCreate
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* UPDATE INITIAL QUESTION */
  const onSubmitUpdateInitialQuestion = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const initiaQuestionUpdate = await api.patch(`/initial/question/${id}`, data, config);

      const listInitialQuestions = await api.get("/initial/question", config);
      setInitialQuestions(listInitialQuestions.data)

      return initiaQuestionUpdate
    }
    catch (err) {
      console.log(err.response)
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* UPDATE INITIAL QUESTION OPTION */
  const onSubmitUpdateInitialQuestionOption = async (id, data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const initiaQuestionOptionUpdate = await api.patch(`/initial/question/option/${id}`, data, config);

      const listInitialQuestionsOption = await api.get("/initial/question", config);
      setInitialQuestions(listInitialQuestionsOption.data)

      return initiaQuestionOptionUpdate
    }
    catch (err) {
      console.log(err.response)
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* DELETE INITIAL QUESTION */
  const onSubmitDeleteInitialQuestion = async (id) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const initialQuestionDelete = await api.delete(`/initial/question/${id}`, config);

      const listInitialQuestionsOption = await api.get("/initial/question", config);
      setInitialQuestions(listInitialQuestionsOption.data)

      return initialQuestionDelete
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* ------------ PARTNERS ------------------ */

  /* ADD PARTNERS */
  const onSubmitAddPartners = async (data) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const partnerCreate = await api.post("/partner", data, config);

      const listPartner = await api.get("/partner", config);
      setPartners(listPartner.data)

      return partnerCreate
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  /* UPDATE PARTNERS */
  const onSubmitUpdatePartners = async (dataPartner, dataContract, dataForm, id) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const partnerUpdate = await api.patch(`/partner/${id}`, dataPartner, config);
      const contractUpdate = await api.patch(`/partner/contract/${id}`, dataContract, config);
      const formUpdate = await api.patch(`/partner/form/${id}`, dataForm, config);

      const listPartner = await api.get("/partner", config);
      setPartners(listPartner.data)

      return true
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }


  /* GET BY ID PARTNERS */
  const onSubmitGetByIdPartners = async (id) => {
    const token = localStorage.getItem("@QuboAdmn:")

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    try {
      const listPartner = await api.get(`/partner/${id}`, config);
      const listUserPartner = await api.get(`/partner/account/${id}`, config);
      setPartner(listPartner.data)
      setUserPartner(listUserPartner.data)

      return { ...listPartner.data, user: listUserPartner.data }
    }
    catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  return (
    <>
      <DashboardContext.Provider
        value={{
          users,
          usersFilter, 
          setUsersFilter,
          macro,
          setMacro,
          column,
          setColumn,
          onSubmitAddMacro,
          onSubmitGetByIdMacro,
          onSubmitUpdateMacro,
          onSubmitDeleteMacro,
          onSubmitDeleteMacro,
          onSubmitAddColumn,
          onSubmitGetByIdColumn,
          onSubmitUpdateColumn,
          onSubmitDeleteColumn,
          journey,
          setJourney,
          onSubmitAddJourney,
          onSubmitUpdateJourney,
          onSubmitAddQuestion,
          onSubmitUpdateQuestion,
          onSubmitDeleteQuestion,
          onSubmitGetByIdQuestion,
          onSubmitGetJourney,
          onSubmitDeleteJourney,
          initialQuestions,
          setInitialQuestions,
          onSubmitAddInitialQuestion,
          onSubmitUpdateInitialQuestion,
          onSubmitUpdateInitialQuestionOption,
          onSubmitDeleteInitialQuestion,
          partners,
          onSubmitAddPartners,
          onSubmitUpdatePartners,
          onSubmitGetByIdPartners,
          partner,
          userPartner,
        }}
      >
        {children}
      </DashboardContext.Provider>
    </>
  );
};
