import { useContext, useState } from "react"
import Button from "./Button"
import ModalContainer from "./ModalContainer"
import CreateOpportunities from "./CreateOpportunities"
import { DashboardPartnerContext } from "../../../context/Dashboard Partner/DashboardContext"



const CardOpportunities = ({ name, description, modalEdit, setModalEdit, onClick }) => {
    const { modal, setModal, setModalDelete, onSubmitAddOpportunities } = useContext(DashboardPartnerContext)

    return (
        <>
            <div className="containerBox" style={{
                maxWidth: "319px",
                height: "303px",
                backgroundColor: "#fff",
                border: "1px solid #d7d7d7",
                borderRadius: "10px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                margin: 0
            }}>
                <div>
                    <div>
                        <p className="textTitlePartnerPageCard">Nome da oportunidade</p>
                        <p className="textContentPartnerPageCard">{name}</p>
                    </div>

                    <div>
                        <p className="textTitlePartnerPageCard">Descrição da oportunidade</p>
                        <p className="textContentPartnerPageCard">{description}</p>
                    </div>
                </div>

                <div style={{
                    marginTop: "17px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "17px"
                }}>
                    <Button width="100%" height="37px" background={"var(--color-primary)"}
                        borderRadius="60px" marginTop="0" name="Editar oportunidade"
                        color={"var(--white)"} selectBtn="Editar oportunidade"
                        border="none" onClick={() => {
                            onClick()
                            setModalEdit(true)
                            setModal(true)
                        }} />

                    <Button width="100%" height="37px" background={"#FFE1E1"}
                        borderRadius="60px" marginTop="0" name="Excluir oportunidade"
                        color={"#EA0000"} selectBtn="Excluir oportunidade"
                        border="none" onClick={()=> {
                            onClick()
                            setModalDelete(true)
                        }} />
                </div>

            </div>
        </>
    )
}

export default CardOpportunities