import React from "react";
const ThowColumLayoutLogin = ({ colum1, colum2, background }) => {
  const container = {
    background: background,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  };

  const colum1Layout = {
    gridColumnStart: "1",
    gridColumnEnd: "2",
  };

  const colum2Layout = {
    gridColumnStart: "2",
    gridColumnEnd: "2",
  };

  return (
    <div style={container}>
      <div style={colum1Layout}>{colum1}</div>

      <div style={colum2Layout}>{colum2}</div>
    </div>
  );
};

export default ThowColumLayoutLogin;
