import React, { useState, useEffect } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import FilterUsers from "../components/FilterUsers";
import CardSignature from "../components/CardSignature";
import api from "../../../services/api";

const Proposals = () => {
  const [clientsInteress, setClientsInteress] = useState([]);
  const [contract, setContract] = useState({});

  const getInteress = async () => {
    const token = localStorage.getItem("@QuboPartner:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    try {
      const interess = await api.get("/partner/interess/all", config);
      setClientsInteress(interess.data);
      //console.log(interess.data)
    } catch (error) {
      console.log(error);
    }
  };

  const getContract = async () => {
    try {
      const profile = await api.get("/profile");

      const contract = await api.get(`/partner/${profile.data.id}`);
      setContract(contract.data.contract[0].description);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInteress();
    getContract();
  }, []);

  return (
    <ThowColumLayout
      page={"Proposals"}
      colum1={<Sidebar access="proposals" page="proposals" />}
      colum2={
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "45px",
            }}
          >
            <HeaderDashboard name="Propostas" />

            <FilterUsers />
          </div>

          <div
            className="containerBox"
            style={{
              display: "flex",
              flexWrap: "wrap",
              // justifyContent: "space-between",
              marginBottom: "50px",
              gap: "20px",
            }}
          >
            {clientsInteress.map((item) => {
              if (item.state == "signed contract") {
                console.log(item);
                return (
                  <CardSignature
                    client={item.client}
                    key={item.id}
                    id={item.id}
                    keysInteress={Object.keys(item)}
                    value={item}
                    status={item.state}
                    contract={item?.contract}
                    annex_one_purpose={item?.annex_one_purpose}
                    annex_one_scope={item?.annex_one_scope}
                    annex_one_price_and_conditions={
                      item?.annex_one_price_and_conditions
                    }
                  />
                );
              }
            })}
          </div>
        </>
      }
    ></ThowColumLayout>
  );
};

export default Proposals;
