import { useEffect, useState } from "react";
import UploadImg from "./UploadImg";
import Button from "./Button";
import ModalContainer from "./ModalContainer";
import ModalContract from "./ModalContract";
import { useContext } from "react";
import { DashboardPartnerContext } from "../../../context/Dashboard Partner/DashboardContext";
import app from "../../../firebase/database";
import mammoth from "mammoth/mammoth.browser";

import { toast } from "react-toastify";

const CardSignature = ({
  keysInteress,
  value,
  id,
  status,
  client,
  contract,
  annex_one_purpose,
  annex_one_scope,
  annex_one_price_and_conditions,
}) => {
  const { onSubmitAddCustomerProposal } = useContext(DashboardPartnerContext);
  const [modalActiveContract, setModalActiveContract] = useState("");

  const [signature, setSignature] = useState(true);
  const [uploadProposal, setUploadProposal] = useState("");
  const [attachmentOne, setAttachmentOne] = useState("");

  const [attachmentTwo, setAttachmentTwo] = useState("");
  const [attachmentThree, setAttachmentThree] = useState("");
  const [idCard, setIdCard] = useState("");

  //   const uploadFirebase = async (item, setState) => {
  //     if (item) {
  //       const reader = new FileReader();
  //       reader.onload = function (event) {
  //         // O resultado é armazenado em event.target.result
  //         const base64String = event.target.result;
  //         console.log(base64String);
  //         setState(base64String);
  //       };
  //       reader.readAsDataURL(item);
  //     }
  //     console.log(item);
  //     // await app.storage()
  //     //     .ref(`/proposal/${item.name}`)
  //     //     .put(item)
  //     //     .then(() => {
  //     //         return app.storage().ref(`/proposal/${item.name}`).getDownloadURL();
  //     //     })
  //     //     .then(async (url) => {
  //     //         setState(url)
  //     //     });
  //   };

  const uploadFirebase = async (item, setState, pdf) => {
    // console.log(item);

    if (item) {
      if (pdf && item.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = function (event) {
          const base64String = event.target.result;
          console.log(base64String);
          setState(base64String);
        };
        reader.readAsDataURL(item);
      } else if (
        !pdf &&
        item.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const result = await mammoth.convertToHtml({ arrayBuffer: item });
        const html = result.value;

        setState(html);
      } else {
        toast.error(
          `Formato inválido. Aceitamos apenas arquivos em formato ${
            pdf ? "PDF" : "DOCS"
          }.`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };

  const modalContractVerify = () => {
    if (
      !uploadProposal ||
      !attachmentOne ||
      !attachmentTwo ||
      !attachmentThree
    ) {
      return toast.error("Verifique sua todos os uploads foram realizados.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIdCard(id);
      setModalActiveContract(true);
    }
  };

  const letterLimit = (text, limit, threePoints) => {
    let description = [];

    if (threePoints) {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    } else {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }

    return description;
  };

  const onHandleSignature = (e) => {
    let value = e.target.value;

    if (!status) {
      if (value == "Assinar contrato") {
        return setSignature(true);
      }
      setSignature(false);
    } else {
      setSignature(false);
    }
  };

  const createProposal = async () => {
    if (
      !uploadProposal ||
      !attachmentOne ||
      !attachmentTwo ||
      !attachmentThree
    ) {
      return toast.error("Verifique sua todos os uploads foram realizados.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const toastId = toast.loading("Enviando dados...", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const listUploads = [
      uploadProposal,
      attachmentOne,
      attachmentTwo,
      attachmentThree,
    ];

    let uploadProposalFirebase = uploadProposal;
    let attachmentOneFirebase = attachmentOne;
    let attachmentTwoFirebase = attachmentTwo;
    let attachmentThreeFirebase = attachmentThree;

    {
      /*for (let index = 0; index < listUploads.length; index++) {
            const item = listUploads[index];
            await app.storage()
                .ref(`/proposal/${item.name}`)
                .put(item)
                .then(() => {
                    return app.storage().ref(`/proposal/${item.name}`).getDownloadURL();
                })
                .then(async (url) => {
                    if (index === 0) {
                        uploadProposalFirebase = url;
                    } else if (index === 1) {
                        attachmentOneFirebase = url;
                    } else if (index === 2) {
                        attachmentTwoFirebase = url;
                    } else if (index === 3) {
                        attachmentThreeFirebase = url;
                    }
                });
        }*/
    }

    const data = {
      proposal: uploadProposalFirebase,
      annex_one_purpose: attachmentOneFirebase,
      annex_one_scope: attachmentTwoFirebase,
      annex_one_price_and_conditions: attachmentThreeFirebase,
    };

    try {
      const respose = await onSubmitAddCustomerProposal(data, id);
      toast.dismiss(toastId);

      if (respose === true) {
        toast.success("Contrato enviado com sucesso!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      setIdCard(id);
    }
  }, [id]);

  useEffect(() => {
    if (status) {
      setSignature(false);
    }
  }, [status]);

  const lineBoxCard = {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
  };

  return (
    <>
      <div
        style={{
          width: "319px",
          border: "1px solid #D7D7D7",
          borderRadius: "10px",
          backgroundColor: "#fff",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "17px",
            }}
          >
            {keysInteress?.map((item) => {
              if (typeof value[item] == "string") {
                if (item == "state") {
                  return false;
                }
                if (
                  item != "id" &&
                  item != "details" &&
                  item != "contract" &&
                  item != "proposal" &&
                  item != "annex_one_purpose" &&
                  item != "annex_one_scope" &&
                  item != "annex_one_price_and_conditions"
                ) {
                  return (
                    <div style={lineBoxCard}>
                      <p className="textTitlePartnerPageCard">{item}</p>
                      <p className="textContentPartnerPageCard">
                        {value[item]}
                      </p>
                    </div>
                  );
                }
                if (item == "details") {
                  const description = letterLimit(value[item], 80, true);
                  return (
                    <div style={lineBoxCard}>
                      <p className="textTitlePartnerPageCard">
                        Observações gerais
                      </p>
                      <p className="textContentPartnerPageCard">
                        {description}
                      </p>
                    </div>
                  );
                }
              }
            })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div>
              <p className="textTitlePartnerPageCard">Status</p>
            </div>

            {status !== "signed contract" ? (
              <select
                className={signature ? "signatureOn" : "signatureOff"}
                onChange={onHandleSignature}
              >
                <option value={"Assinar contrato"}>Assinar contrato</option>
                <option value={"Em negociação"}>Em negociação</option>
              </select>
            ) : (
              <select className={"signatureOn"}>
                <option value={"Contrato assinado"}>Contrato assinado</option>
              </select>
            )}
          </div>
          {status !== "signed contract" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UploadImg
                status={status ? false : true}
                id={id}
                proposal={status ? true : uploadProposal}
                onUpload={(img) => uploadFirebase(img, setUploadProposal, true)}
                pdf={true}
                name={"Faça o upload da proposta"}
              />
              <UploadImg
                status={status ? false : true}
                id={id}
                proposal={status ? true : attachmentOne}
                onUpload={(img) => uploadFirebase(img, setAttachmentOne)}
                name={"Anexo I - Objeto"}
              />
              <UploadImg
                status={status ? false : true}
                id={id}
                proposal={status ? true : attachmentTwo}
                onUpload={(img) => uploadFirebase(img, setAttachmentTwo)}
                name={"Anexo II - Escopo"}
              />
              <UploadImg
                status={status ? false : true}
                id={id}
                proposal={status ? true : attachmentThree}
                onUpload={(img) => uploadFirebase(img, setAttachmentThree)}
                name={"Anexo III - Preço e Condições"}
              />
            </div>
          )}
        </div>

        <div style={{ width: "100%", marginTop: "17px" }}>
          <Button
            width={"100%"}
            height="35px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name={
              status == "signed contract"
                ? "Visualizar contrato"
                : signature
                ? "Assinar contrato"
                : "Contrato indisponível"
            }
            selectBtn={
              status == "signed contract"
                ? "Visualizar contrato"
                : signature
                ? "Assinar contrato"
                : "Contrato indisponível"
            }
            disabled={status == "signed contract" ? false : !signature}
            border="none"
            onClick={
              status == "signed contract"
                ? () => {
                    setIdCard(id);
                    setModalActiveContract(true);
                  }
                : modalContractVerify
            }
          />
        </div>

        <ModalContainer
          isActive={modalActiveContract}
          closeModal={() => {
            setModalActiveContract(!modalActiveContract);
          }}
        >
          <ModalContract
            btnActive={status == "signed contract" ? false : true}
            urls={
              status == "signed contract"
                ? [
                    "",
                    annex_one_purpose,
                    annex_one_scope,
                    annex_one_price_and_conditions,
                  ]
                : [
                    uploadProposal,
                    attachmentOne,
                    attachmentTwo,
                    attachmentThree,
                  ]
            }
            contract={contract}
            client={client}
            setModalActive={setModalActiveContract}
            id={idCard}
            onClick={createProposal}
            closeModal={() => {
              setModalActiveContract(!modalActiveContract);
            }}
          />
        </ModalContainer>
      </div>
    </>
  );
};

export default CardSignature;
